var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    attrs: {
      id: _vm.id,
      allowfullscreen: _vm.allowfullscreen,
      frameborder: _vm.frameborder,
      src: _vm.url,
      autoplay: "true",
      width: "100%",
      height: "100%",
      allow: _vm.allow
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <iframe
    :id="id"
    :allowfullscreen="allowfullscreen"
    :frameborder="frameborder"
    :src="url"
    autoplay="true"
    width="100%"
    height="100%"
    :allow="allow"
  />
</template>

<script>
export default {
  name: "YouTube",
  props: {
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 500
    },
    start: {
      type: Number,
      default: 0
    },
    frameborder: {
      type: Number,
      default: 0
    },
    isTwitch: {
      type: Boolean,
      default: false
    },
    allowfullscreen: {
      type: Boolean,
      default: true
    },
    videoCode: {
      type: String,
      required: false
    },
    twitchChannel: {
      type: String,
      required: false
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    canPlay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      youtubeUrl: "https://www.youtube.com/embed/",
      twitchUrl: "https://player.twitch.tv/",
      player: null
    }
  },
  computed: {
    id() {
      return `youtube-${this._uid}`
    },
    url() {
      if (this.isTwitch) {
        return `${this.twitchUrl}?channel=${this.twitchChannel}&parent=${window.location.hostname}`
      } else {
        return `${this.youtubeUrl}${this.videoCode}?start=${this.start}&enablejsapi=1`
      }
    },
    allow() {
      return this.isTwitch
        ? null
        : "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    }
  },
  mounted() {
    if (this.isTwitch === false) {
      // eslint-disable-next-line no-undef
      this.player = new YT.Player(this.id, {
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange
        }
      })
    }
  },
  methods: {
    onPlayerReady() {
      if (this.autoplay && this.isTwitch === false) {
        this.tryPlayVideo()
      }
    },
    /**
     * @param {{ data: number }} event
     */
    onPlayerStateChange({ data }) {
      if (data == 0) {
        this.$emit("end")
      }
    },
    tryPlayVideo() {
      if (this.canPlay && this.player && this.player.playVideo)
        this.player.playVideo()
    }
  },
  watch: {
    isExpoTourCompleted() {
      this.tryPlayVideo()
    },
    canPlay(value) {
      // console.log(value)
      if (value) this.tryPlayVideo()
      else this.player.pauseVideo()
    }
  }
}
</script>
